import { ModelSelect } from 'vue-search-select';
import DatePicker from 'vue2-datepicker';
import commonHelper from '@/app/utility/common.helper.utility';
import appStrings from '@/app/utility/string.utility';
import addEditUnitSubunit from './addEditUnitSubunit';
export default {
    name: 'BillingCycle',
    components: {
        ModelSelect,
        DatePicker,
        addEditUnitSubunit
    },
    watch: {
        currentPage: function () {
            this.getBillingCycleList();
        },
        perPage: function () {
            this.currentPage = 1;
            this.getBillingCycleList();
        }
    },
    data() {
        return {
            unitSubunitRowData: null,
            showUnitSubunitModal: false,
            loader: false,
            unsubscribe: null,
            totalRows: null,
            payload: null,
            currentPage: 1,
            perPage: commonHelper.perPageRecord,
            pageOptions: commonHelper.getPageOption(),
            vsetCode: null,
            showValueSetModal: false,
            setTimeVsetCode: null,
            parent_value_set_id: null,
            fmsPrj: {
                value: null,
                text: null
            },
            type: {
                value: null,
                text: null
            },
            unitSubunit: {
                value: null,
                text: null
            },
            unitSubunitData: [],
            unitSubunitFields: [
                {
                    key: 'fms_prj_name',
                    label: 'FMS Project'
                },
                {
                    key: 'type'
                },
                {
                    key: 'unit_display_name',
                    label: 'FMS Unit'
                },
                {
                    key: 'fms_sub_unit_name',
                    label: 'FMS Sub Unit'
                },
                {
                    key: 'billable_area_uom_vset',
                    label: 'Billable Area UOM'
                },
                {
                    key: 'billable_area'
                },
                {
                    key: 'fms_billing_area_enviro'
                },
            ]
        };
    },
    mounted() {},
    methods: {
        getUnitSubunitData() {
            if(this.fmsPrj.value && this.type && this.unitSubunit.value){
            this.payload = {
                _page: this.currentPage - 1,
                _limit: this.perPage,
                Id: this.unitSubunit.value,
                type: this.type.value
            };
            this.loader = true;
            this.$store
                .dispatch('masterData/getUnitSubunitData', this.payload)
                .then(response => {
                    this.loader = false;
                    if (response.status === 200) {
                        this.unitSubunitData = response.data.data;
                        this.totalRows = response.data.data.total_elements;
                    }
                })
                .catch(() => {
                    this.loader = false;
                });
            } else {
                alert('Please Fill All Mandatory Fields')
            }
        },
        openValueSetModal(vsetCode) {
            this.vsetCode = vsetCode;
            this.parent_value_set_id = null;
            this.showValueSetModal = true;
            if (this.vsetCode === appStrings.VALUESETTYPE.FMS_UNIT_DISPLAY || this.vsetCode === appStrings.VALUESETTYPE.FMS_SUB_UNIT_DISPLAY) {
                this.parent_value_set_id = this.fmsPrj.value;
            }
            this.setTimeVsetCode = setTimeout(() => {
                const vSetData = {
                    valueSetName: vsetCode,
                    multiFlag: null
                };
                this.eventBus.$emit('valueSetCode', vSetData);
            }, 0);
        },
        selectedvalueSet(item) {
            if (this.vsetCode === appStrings.VALUESETTYPE.FMS_PRJ_INTERNAL) {
                this.fmsPrj.text = item.fms_prj_name;
                this.fmsPrj.value = item.fms_prj_id;
            } else if (this.vsetCode === appStrings.VALUESETTYPE.TYPE) {
                this.type.text = item.value_meaning;
                this.type.value = item.value_code;
                this.unitSubunit.text = null;
                this.unitSubunit.value = null;
            } else if (this.vsetCode === appStrings.VALUESETTYPE.FMS_UNIT_DISPLAY) {
                this.unitSubunit.text = item.unit_name;
                this.unitSubunit.value = item.fms_unit_id;
            } else if (this.vsetCode === appStrings.VALUESETTYPE.FMS_SUB_UNIT_DISPLAY) {
                this.unitSubunit.text = item.fms_sub_unit_name;
                this.unitSubunit.value = item.fms_sub_unit_id;
            }
        },
        closeValueSetModal() {
            this.showValueSetModal = false;
        },
        clearVsetValues(vsetCode) {
            if (vsetCode === appStrings.VALUESETTYPE.FMS_PRJ_INTERNAL) {
                this.fmsPrj.text = null;
                this.fmsPrj.value = null;
            } else if (vsetCode === appStrings.VALUESETTYPE.TYPE) {
                this.type.text = null;
                this.type.value = null;
            } else if (vsetCode === appStrings.VALUESETTYPE.FMS_UNIT_DISPLAY || vsetCode === appStrings.VALUESETTYPE.FMS_SUB_UNIT_DISPLAY) {
                this.unitSubunit.text = null;
                this.unitSubunit.value = null;
            }
        },
        showhideUnitSubunitModal(flag) {
            this.showUnitSubunitModal = flag;
            if(!flag){
              this.getUnitSubunitData();
            }
        },
        rowSelected(item) {
            this.unitSubunitRowData = item;
            this.showUnitSubunitModal = true;
        },
        checkUnitorSubUnit(type) {
            if (type === 'UNIT') {
                this.openValueSetModal('FMS_UNIT_DISPLAY')
            } else if (type === 'SUBUNIT') {
                this.openValueSetModal('FMS_SUB_UNIT_DISPLAY')
            } else{
                alert('Please Select FMS Project and Type')
            }
        },
        clearSearch() {
            this.type.value = null;
            this.type.text = null;
            this.fmsPrj.value = null;
            this.fmsPrj.text = null;
            this.unitSubunit.text = null;
            this.unitSubunit.value = null;
            this.unitSubunitData = [];
            this.totalRows = null;
            this.parent_value_set_id = null;
        },
    },
    beforeDestroy() {
        // this.unsubscribe();
    }
};
