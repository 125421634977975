import commonHelper from '@/app/utility/common.helper.utility';
import appStrings from '@/app/utility/string.utility';
export default {
    name: 'BillingCycle',
    components: {},
    props: ['unitSubunitRowData'],
    watch: {
        currentPage: function () {
            this.getBillingCycleList();
        },
        perPage: function () {
            this.currentPage = 1;
            this.getBillingCycleList();
        }
    },
    data() {
        return {
            loader: false,
            unsubscribe: null,
            totalRows: null,
            payload: null,
            currentPage: 1,
            isSuccess: false,
            showAlert: false,
            responseMsg: '',
            perPage: commonHelper.perPageRecord,
            pageOptions: commonHelper.getPageOption(),
            vsetCode: null,
            showValueSetModal: false,
            setTimeVsetCode: null,
            parent_value_set_id: null,
            editMode: false,
            fmsPrj: {
                value: null,
                text: null
            },
            type: {
                value: null,
                text: null
            },
            unitSubunit: {
                value: null,
                text: null
            },
            billableAreaEnviro: null,
            billableArea: null,
            unitOrgAreaId: null,
            unitSubunitData: [],
            unitSubunitFields: [
                {
                    key: 'le_name'
                },
                {
                    key: 'billing_cycle_name',
                    label: 'Billing Cycle'
                },
                {
                    key: 'cycle_start_date',
                    label: 'Start Date'
                },
                {
                    key: 'duration'
                },
                {
                    key: 'uom_meaning',
                    label: 'UOM'
                },
                {
                    key: 'payment_term',
                    label: 'Payment'
                },
                {
                    key: 'penalty_payment_term',
                    label: 'Penalty'
                }
            ]
        };
    },
    mounted() {
        if (this.unitSubunitRowData) {
            this.fillRecordFromUnitSubunitParent(this.unitSubunitRowData);
        } else {
            this.editMode = true;
        }
        this.unsubscribe = this.$store.subscribe((mutation, state) => {
            if (mutation.type === 'shared/setActionName') {
                const actionName = state.shared.actionName;
                if (actionName === 'save' && this.editMode) {
                    this.updateUnitSubunitEnviroArea();
                }
                if (actionName === 'update') {
                    this.editMode = true;
                }
            }
        });
    },
    methods: {
        fillRecordFromUnitSubunitParent(item) {
            this.billableArea = item.billable_area;
            this.BillableAreaUom = item.billable_area_uom_vset;
            this.billableAreaEnviro = item.fms_billing_area_enviro;
            this.fmsPrj.value = item.fms_prj_id;
            this.fmsPrj.text = item.fms_prj_name;
            this.unitSubunit.value = item.fms_sub_unit_id;
            this.unitSubunit.text = item.fms_sub_unit_name;
            this.fmsUnitAreaId = item.fms_unit_area_id;
            this.unitSubunit.value = item.fms_unit_id;
            this.type.text = item.type;
            this.type.value = item.type;
            this.unitSubunit.text = item.unit_display_name;
            this.unitOrgAreaId = item.unit_org_area_id;
        },
        updateUnitSubunitEnviroArea() {
            const payload = {
                id: this.type.value === 'UNIT' ? this.unitSubunitRowData.unit_org_area_id : this.type.value === 'SUBUNIT' ? this.unitSubunitRowData.fms_unit_area_id : null,
                type: this.type.value,
                fms_billing_area_enviro: this.billableAreaEnviro,
            };
            this.loader = true;
            this.$store
                .dispatch('masterData/updateUnitSubunitEnviroArea', payload)
                .then(response => {
                    this.loader = false;
                    this.showAlert = true;
                    if (response.status === 201) {
                        this.editMode = false;
                        this.isSuccess = true;
                        this.responseMsg = response.data.message;
                    } else {
                        this.isSuccess = false;
                        this.responseMsg = response.response.data.message;
                    }
                })
                .catch(() => {
                    this.loader = false;
                    this.showAlert = true;
                    this.isSuccess = false;
                    this.responseMsg = appStrings.autoFailedMsg;
                });
        },
        getFmsUnitOrgArea() {
            const payload = {
                fmsUnitId: this.unitSubunit.value
            };
            this.loader = true;
            this.$store
                .dispatch('unitInventory/getFmsUnitOriginalArea', payload)
                .then(response => {
                    this.loader = false;
                    const results = response.data.data;
                    this.billableAreaEnviro = results.fms_billing_area_enviro;
                    this.billableArea = results.billable_area;
                })
                .catch(() => {
                    this.loader = false;
                });
        },
        getFmsSubUnitRevisedArea() {
            const payload = {
                fmsSubUnitId: this.unitSubunit.value
            };
            this.loader = true;
            this.$store
                .dispatch('unitInventory/getFmsSubUnitRevisedArea', payload)
                .then(response => {
                    this.loader = false;
                    const results = response.data.data;
                    this.billableAreaEnviro = results[0].fms_billing_area_enviro;
                    this.billableArea = results[0].billable_area;
                })
                .catch(() => {
                    this.loader = false;
                });
        },
        openValueSetModal(vsetCode) {
            this.vsetCode = vsetCode;
            this.parent_value_set_id = null;
            this.showValueSetModal = true;
            if (this.vsetCode === appStrings.VALUESETTYPE.FMS_UNIT_DISPLAY || this.vsetCode === appStrings.VALUESETTYPE.FMS_SUB_UNIT_DISPLAY) {
                this.parent_value_set_id = this.fmsPrj.value;
            }
            this.setTimeVsetCode = setTimeout(() => {
                const vSetData = {
                    valueSetName: vsetCode,
                    multiFlag: null
                };
                this.eventBus.$emit('valueSetCode', vSetData);
            }, 0);
        },
        selectedvalueSet(item) {
            if (this.vsetCode === appStrings.VALUESETTYPE.FMS_PRJ_INTERNAL) {
                this.fmsPrj.text = item.fms_prj_name;
                this.fmsPrj.value = item.fms_prj_id;
            } else if (this.vsetCode === appStrings.VALUESETTYPE.TYPE) {
                this.type.text = item.value_meaning;
                this.type.value = item.value_code;
                this.unitSubunit.text = null;
                this.unitSubunit.value = null;
            } else if (this.vsetCode === appStrings.VALUESETTYPE.FMS_UNIT_DISPLAY) {
                this.unitSubunit.text = item.unit_name;
                this.unitSubunit.value = item.fms_unit_id;
                this.getFmsUnitOrgArea();
            } else if (this.vsetCode === appStrings.VALUESETTYPE.FMS_SUB_UNIT_DISPLAY) {
                this.unitSubunit.text = item.fms_sub_unit_name;
                this.unitSubunit.value = item.fms_sub_unit_id;
                this.getFmsSubUnitRevisedArea();
            }
        },
        qtyTwoDecimalValue() {
            let billableAreaEnviro = this.billableAreaEnviro.toString().split('.');
            if (billableAreaEnviro.length > 1 && billableAreaEnviro[1].length>4) {
            this.billableAreaEnviro = parseFloat(this.billableAreaEnviro).toFixed(4);
             alert('Only Four Decimal Value Allowed.')
            }
        },
        closeValueSetModal() {
            this.showValueSetModal = false;
        },
        clearVsetValues(vsetCode) {
            if (vsetCode === appStrings.VALUESETTYPE.FMS_PRJ_INTERNAL) {
                this.fmsPrj.text = null;
                this.fmsPrj.value = null;
            } else if (vsetCode === appStrings.VALUESETTYPE.TYPE) {
                this.type.text = null;
                this.type.value = null;
            } else if (vsetCode === appStrings.VALUESETTYPE.FMS_UNIT_DISPLAY) {
                this.unitSubunit.text = null;
                this.unitSubunit.value = null;
            }
        },
        checkUnitorSubUnit(type) {
            if (type === 'UNIT') {
                this.openValueSetModal('FMS_UNIT_DISPLAY')
            } else if (type === 'SUBUNIT') {
                this.openValueSetModal('FMS_SUB_UNIT_DISPLAY')
            } else {
                alert('Please Select FMS Project and Type')
            }
        },
        clearSearch() {
            this.type.value = null;
            this.type.text = null;
            this.fmsPrj.value = null;
            this.fmsPrj.text = null;
            this.unitSubunit.text = null;
            this.unitSubunit.value = null;
            this.unitSubunitData = [];
            this.totalRows = null;
            this.parent_value_set_id = null;
        },
    },
    beforeDestroy() {
        this.unsubscribe();
    }
};
